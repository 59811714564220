import React, { useState } from "react"

import Layout from "../../components/layout"
import { CopyToClipboard } from "react-copy-to-clipboard"
import DownloadSVG from "../../components/download-svg"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Campaign = ({ pageContext }) => {
  const { campaign } = pageContext

  return (
    <Layout>
      <h3>
        Links in: <span dangerouslySetInnerHTML={{ __html: campaign.title }} />
      </h3>
      <table>
        <thead>
          <tr>
            <th className="center">#</th>
            <th>Final Destination</th>
            <th colSpan="3">Short Link</th>
          </tr>
        </thead>
        <tbody>
          {campaign.acf && campaign.acf.links ? (
            campaign.acf.links.map((link, i) => {
              const count = i + 1
              return (
                <Row
                  key={link.key}
                  link={link}
                  count={count}
                  campaign={campaign}
                  linkKey={link.key}
                />
              )
            })
          ) : (
            <tr>
              <td>No Links Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </Layout>
  )
}
export default Campaign

const Row = ({ link, linkKey, campaign, count }) => {
  const [copied, setCopied] = useState(false)

  return (
    <tr>
      <td className="right">{count}</td>
      <td>
        <a href={link.url}>{link.url}</a>
      </td>
      <td width="50">
        <a
          href={`/${campaign.slug}/${linkKey}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button"
        >
          <span className="tooltip">{`https://getmylink.co.za/${campaign.slug}/${linkKey}`}</span>
          Visit
        </a>
      </td>
      <td width="65">
        <CopyToClipboard
          text={`https://getmylink.co.za/${campaign.slug}/${linkKey}`}
          onCopy={() => setCopied(true)}
        >
          <button className={copied ? "copied" : "copy"}>
            {copied ? "Copied" : "Copy"}
          </button>
        </CopyToClipboard>
      </td>
      <td width="75">
        <DownloadSVG name={linkKey} value={`/${campaign.slug}/${linkKey}`} />
      </td>
    </tr>
  )
}

import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import QRCode from "react-qr-code"
import download from "downloadjs"
import htmlToImage from "html-to-image"

function DownloadSVG({ value = "", name = "file" }) {
  var ref = React.useRef()
  var [loading, setLoading] = useState(false)
  var [complete, setComplete] = useState(false)

  return (
    <Container>
      <Button
        className={complete ? "complete" : "untouched"}
        onClick={() => {
          setLoading(true)
          htmlToImage
            .toPng(ref.current.children.item(0))
            .then(function (dataUrl) {
              download(dataUrl, name + ".png")
              setLoading(false)
              setComplete(true)
            })
        }}
      >
        {loading ? <Loader /> : "QR Code"}
      </Button>
      <Hidden ref={ref}>
        {typeof window !== "undefined" && (
          <QRCode value={window.location.origin + value} />
        )}
      </Hidden>
    </Container>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  min-width: 75px;
  height: 32px;
  text-align: center;
  justify-content: center;
  &.complete {
    background-color: #5bd460;
  }
`
const Container = styled.div`
  position: relative;
  overflow: hidden;
`
const Hidden = styled.div`
  position: absolute;
`

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
    `

const Loader = styled.div`
  margin-left: 5px;
  border: 3px solid rgba(255, 255, 255, 0.15);
  border-top: 3px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
`

export default DownloadSVG
